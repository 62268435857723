export const CUSTOM_PRICE_RANGE_FORM = 'CUSTOM_PRICE_RANGE_FORM'

export const QTY_DEFAULT_VALUE = 2
export const PRICE_SET_MIN = 2
export const PRICE_SET_MAX = 4
export const PROMOTIONS_MIN_ITEMS = 2
export const SEARCH_PROMOTION_TYPE = 'BOTSearchPromotion'
export const GENERIC_SEARCH_PROMOTION_TYPE = 'GenericPromotionBlock'
export const PRICE_PER_TIRE_NAME = 'Price Per Tire'
export const TIRE_BRANDS = 'Tire Brands'
export const PERFORMANCE = 'Performance'
export const SEASONALITY = 'Seasonality'
export const WARRANTY = 'Warranty'
export const SPEED_RATING = 'Speed Rating'
export const LOAD_RANGE = 'Load Range'
export const RUN_FLAT = 'Run Flat'
export const CUSTOM_PRICE = 'Custom Price'

export const COMPARE_TRAY_MAX_ITEMS = 3
export const RECORDS_INITIAL_COUNT = 6
export const CUSTOM_PRICE_TYPE = 'customPriceRangeType'

export const CUSTOM_PRICE_MIN_PARAM = 'r1'
export const CUSTOM_PRICE_MAX_PARAM = 'r2'
export const NF_PARAM = 'Nf'
export const OE_PARAM = 'oe'

export const RECORDS_PER_PAGE = 12
export const DEFAULT_PROMO_INDEX = 3
export const SEARCH_RESULT_COMPONENT = 'BOTSearchResult'
export const SEARCH_RESULT_URL_PATH = '/search-results'
export const TIRE_PATH_REGEX = /(\/tires)/

export const SEARCH_BASE_REGEXP = /\/tires\/([^/]+)/
export const SEARCH_BY_SIZE_REGEXP = /\/tires\/by-size\/(\d+(\/\d+(?=(\/|$)))?)/
export const SEARCH_BY_TRAILER_REGEXP = /\/tires\/trailer\/(\d+(\/\d+(?=(\/|$)))?)/
export const SEARCH_BY_VEHICLE_REGEXP = /\/tires\/by-vehicle\/\d+\/[^/]+\/[^/]+\/[^/]+\/\d+(\/\d+(?=(\/|$)))?/

export const VEHICLE = 'VEHICLE'

export const INLINE_RECOMMENDED_TIRES_ID = 'Inline-Recommended-Tires-Container'

export const FILTER_DATA_ATTRIBUTES_BY_NAME = {
  [PRICE_PER_TIRE_NAME]: { 'data-at-serp-filter-price': true },
  [TIRE_BRANDS]: { 'data-at-serp-filter-brands': true },
  [PERFORMANCE]: { 'data-at-serp-filter-performance': true },
  [SEASONALITY]: { 'data-at-serp-filter-seasonality': true },
  [WARRANTY]: { 'data-at-serp-filter-warranty': true },
  [SPEED_RATING]: { 'data-at-serp-filter-speedrating': true },
  [LOAD_RANGE]: { 'data-at-serp-filter-loadrange': true },
  [RUN_FLAT]: { 'data-at-serp-filter-runflat': true }
}

export const SEE_FEWER = 'SEE FEWER PROMOS'
export const SEE_ALL = 'SEE ALL PROMOS'
export const SEE_SPECS = 'See Specs'
export const PROMO_VIEW_FEWER_QTY = 2

export const NORESULT_MAIN_TITLE = 'No Results for Your Vehicle'
export const NORESULT_UHNO = 'Uh oh... a big oh no'
export const NORESULT_FOR_VEHICLE = 'We\'re sorry, we don\'t have tires that match your vehicle. Please search tires for a different vehicle.'
export const NORESULT_FOR_SHARED_ITEMS = 'We\'re sorry, we don\'t have tires to show here.'
export const NORESULT_FIRST_SENTENCE = 'We\'re sorry. We don\'t have any results that match your search criteria.'
export const NORESULT_NOSTORE = 'Please contact your nearest store for assistance, '
export const NORESULT_BY_VEHICLE_SUFFIX = 'or select a different vehicle.'
export const NORESULT_BY_SIZE_SUFFIX = 'or select a different tire size.'
export const NORESULT_BY_FILTER_SUFFIX = 'or click here to reset your filter.'
export const SWITCH_VEHICLE = 'Switch Vehicle'
export const SWITCH_TIRE_SIZE = 'Switch Tire Size'

// Search results page query param
export const SRP_PATHNAME = 'srpPathname'
export const TRAILER_TIRES_TEXT = 'TRAILER TIRES'
export const OE_CAME_WITH_VEHICLE = 'Came With Vehicle'
export const NO_PRODUCT_STATIC_MESSAGE = 'NO RESULTS FOR YOUR'
export const PRODUCTS_ARE_AVAILABLE_STATIC_MESSAGE = 'ALL RESULTS FOR YOUR'
