import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Link from 'components/common/Link/Link'
import Icon from 'components/common/Icon/Icon'
import Theme from 'components/common/Theme/Theme'
import Button from 'components/common/Button/Button'
import { HOME, OPEN_APPOINTMENT_MENU } from 'constant'

class HeaderMobileTop extends React.Component {
  componentDidMount() {
    window.addEventListener(OPEN_APPOINTMENT_MENU, this.props.toggleHandlers.onClick)
  }

  componentWillUnmount() {
    window.removeEventListener(OPEN_APPOINTMENT_MENU, this.props.toggleHandlers.onClick)
  }

  render() {
    const {
      expanded,
      toggleHandlers,
      placeholders: { headerContent = []} = {}
    } = this.props

    return (
      <Headline expanded={ expanded }>
        <CTAPlaceholder data-at-header-hamburger-menu link expanded={ expanded } { ...toggleHandlers } >
          { expanded ?
            <Icon alt="Close Navigation" asset="menu-close-white" height={ 14 } width={ 14 } /> :
            <Icon alt="Open Navigation" asset="icon-menu" height={ 12 } width={ 18 } />
          }
        </CTAPlaceholder>
        <MainAreaPlaceholder expanded={ expanded }>
          <Link to={ HOME } data-at-header-home-link>
            <Icon asset="logo" height={ 20 } width={ 171 } />
          </Link>
        </MainAreaPlaceholder>
        { headerContent }
      </Headline>
    )
  }
}

HeaderMobileTop.propTypes = {
  commerceItems: PropTypes.number,
  expanded: PropTypes.bool,
  name: PropTypes.string,
  placeholders: PropTypes.shape({
    headerContent: PropTypes.arrayOf()
  }),
  toggleHandlers: PropTypes.shape({
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func
  })
}

HeaderMobileTop.type = 'HeaderMobileTop'
HeaderMobileTop.placeholders = ['headerContent']

export default HeaderMobileTop

const Headline = styled.div`
  display: table;
  width: 100%;
  height: 60px;
  ${props => props.expanded && css`
    border-bottom: 1px solid ${Theme.colors.light_grey};
  `}
`
const CTAPlaceholder = styled(Button)`
  && {
    display: table-cell;
    padding: 20px;
    vertical-align: middle;
    ${props => props.expanded && css`
      padding: 21px 19px;
      border-right: 1px solid ${Theme.colors.light_grey};
    `}
  }
`

const MainAreaPlaceholder = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  ${props => props.expanded && css`
    visibility: hidden;
  `}
`
